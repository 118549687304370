import {
  Box,
  Chip,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import calendarCyclist from "../../../assets/img/layout/coach/Library/Plans/calendarcyclist.svg";
import "../../../assets/scss/DatePicker.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useUser } from "src/components/Authenticated";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import type { SupabaseCall } from "src/utils/common";

export type PlanComponentProps = {
  tab: string;
};

export default function PlanComponent(props: PlanComponentProps) {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const user = useUser();
  const isMobileScreen = useMediaQuery("(max-width:720px)");

  const assignedTrainingPlanPromise = useCallback(() => {
    const query = supabase
      .from("assigned_training_plan")
      .select("*, assigned_workout!inner(*)")
      .eq("assigned_workout.user_id", user.id);

    if (props.tab === "active") {
      query
        .lte("start_at", new Date().toISOString())
        .gte("end_at", new Date().toISOString());
    }

    if (props.tab === "scheduled") {
      query.gte("start_at", new Date().toISOString());
    }

    if (props.tab === "completed") {
      query.lte("end_at", new Date().toISOString());
    }

    return query.throwOnError();
  }, [supabase]);

  // @ts-ignore
  const assignedTrainingPlanCall = useAsyncState<SupabaseCall<typeof assignedTrainingPlanPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(assignedTrainingPlanCall)) {
      assignedTrainingPlanCall.fire(async () => assignedTrainingPlanPromise());
    }
  }, [supabase, assignedTrainingPlanCall]);

  return (
    <>
      {isFulfilled(assignedTrainingPlanCall) ? (
        assignedTrainingPlanCall.result.data.map((trainingPlan, index) => {
          const startDate = dayjs(trainingPlan.start_at);
          const endDate = dayjs(trainingPlan.end_at);
          const numberOfWeeks = Math.ceil(endDate.diff(startDate, "day") / 7);
          return (
            <Box
              key={trainingPlan.id}
              sx={{
                display: "flex",
                flexDirection: isMobileScreen ? "column" : "row",
                justifyContent: "space-between",
                marginBottom: "1rem",
                ...(assignedTrainingPlanCall.result.data.length > 1 && {
                  borderBottom: "2px solid #BC6D29",
                }),
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <img src={calendarCyclist} width={50} alt="calendar" />
                <Box>
                  <Typography variant="h2" marginBottom={2}>
                    {trainingPlan.name}
                  </Typography>
                  <Stack direction="row" spacing={1} marginBottom={2}>
                    <Chip
                      label={trainingPlan.difficulty}
                      size="small"
                      sx={{
                        backgroundColor: "#F89F5B",
                      }}
                    />
                    {isMobileScreen && (
                      <Typography>{trainingPlan.ride_type}</Typography>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={2} marginBottom={2}>
                    <Typography>{numberOfWeeks} Weeks</Typography>
                    <Typography>
                      {(
                        trainingPlan.assigned_workout.length / numberOfWeeks
                      ).toFixed(1)}{" "}
                      Workouts/Week
                    </Typography>
                    {!isMobileScreen && (
                      <Typography>{trainingPlan.ride_type}</Typography>
                    )}
                  </Stack>
                </Box>
              </Stack>

              <Stack
                alignItems={"flex-end"}
                marginBottom={isMobileScreen ? "0.5rem" : ""}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  marginY={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography>
                    {startDate.format("DD MMM")} -{" "}
                    {endDate.format("DD MMM YYYY")}
                  </Typography>
                </Stack>
                <Link
                  onClick={() => navigate(`${trainingPlan.id}`)}
                  underline="none"
                  sx={{ color: "#E57B77", cursor: "pointer" }}
                >
                  <Typography textAlign={"end"}>View Details</Typography>
                </Link>
              </Stack>
            </Box>
          );
        })
      ) : (
        <SuspenseLoader />
      )}
    </>
  );
}
