import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Modal,
  TableCell as MuiTableCell,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import type { GridRowId } from "@mui/x-data-grid";
import convert from "convert-units";
import dayjs from "dayjs";
import React, { Fragment, useRef } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useUser } from "src/components/Authenticated";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";
import type { TableRow as TR } from "src/contexts/CacheContext";
import { useSupabase } from "src/contexts/SupabaseContext";
import useAsyncState, { isUnloaded, isFulfilled } from "src/utils/Async";
import type { Database } from "src/utils/DatabaseDefinitions";
import {
  type SupabaseCall,
  type Zone,
  allBikePositions,
  timeFormatter,
  valueInZone,
  zoneEdgesToZoneType,
} from "src/utils/common";
import type { PositionStatistics } from "src/utils/types";
import Minilogo from "../../assets/img/layout/miniLogo.svg";

const TableCell = styled(MuiTableCell)({
  width: "20%",
});

type CompareModalProps = {
  ids: GridRowId[];
  showModal: boolean;
  handleCloseModal: () => void;
  defaultPositions: Array<TR<"default_positions">>;
  customPositions: Array<TR<"custom_positions">>;
};

type ZoneWithStats = Zone[] &
  {
    total_time: number;
    percentage: number;
    index: number;
  }[];

export type SessionsType = Database["public"]["Tables"]["session"]["Row"] & {
  athlete_bikes: { bike_types: { name: string } };
  session_statistics: {
    cadence_max: number;
    created_at: string;
    default_position_id: number;
    heart_rate_average: number;
    heart_rate_max: number;
    id: number;
    pedal_balance_left: number;
    pedal_balance_right: number;
    position_percentage: number;
    power_average: number;
    power_max: number;
    session_id: string;
    slope_average: number;
    slope_negative_percentage: number;
    slope_positive_percentage: number;
    speed_average: number;
    speed_max: number;
    cadence_average: number;
  }[];
};

type Statistics = {
  cadence_average: number;
  cadence_max: number;
  created_at: string;
  default_position_id: number;
  custom_position_id: number;
  heart_rate_average: number;
  heart_rate_max: number;
  id: number;
  pedal_balance_left: number;
  pedal_balance_right: number;
  position_percentage: number;
  power_average: number;
  power_max: number;
  session_id: string;
  slope_average: number;
  slope_negative_percentage: number;
  slope_positive_percentage: number;
  speed_average: number;
  speed_max: number;
}[];

type CollapsibleDataProps = {
  title: string;
  positionStatistics: (Omit<PositionStatistics, "positionType"> &
    {
      positionType: string;
    }[])[];
  position:
    | "Standing"
    | "Comfortable - Ideal"
    | "Comfortable - Not Ideal"
    | "TT - Ideal"
    | "TT - Not Ideal"
    | "Aggressive - Ideal"
    | "Aggressive - Not Ideal"
    | "Lower than TT";
  field: string;
  unit: string;
  isPrinting: boolean;
};

type NormarDataProps = {
  title: string;
  sessionCall: any;
  isPrinting: boolean;
};

function NormalData(props: NormarDataProps) {
  const title = props.title;
  const units = useUnits();

  return (
    <>
      <TableRow
        hover
        sx={{ background: props.isPrinting ? "#F7F3F0" : "#2C262D" }}
      >
        <TableCell sx={{ paddingLeft: "55px" }}>
          <Typography
            variant="h4"
            sx={{
              color: props.isPrinting ? "#000000" : "#E28E54",
              fontWeight: props.isPrinting ? "bold" : "",
            }}
          >
            {props.title}
          </Typography>
        </TableCell>
        {props.sessionCall.result.map((session, index) => (
          <TableCell
            key={index}
            sx={{ color: props.isPrinting ? "#000000" : "#F7F3F0" }}
          >
            {title === "Date"
              ? dayjs(session.date).format("DD/MM/YYYY HH:mm:ss")
              : title === "Distance"
              ? units === "metric"
                ? `${session.total_distance?.toFixed(1) ?? "-"} km`
                : `${
                    convert(session.total_distance as number)
                      .from("mi")
                      .to("km")
                      ?.toFixed(1) ?? "-"
                  } mi`
              : title === "Type"
              ? session.athlete_bikes
                ? session.athlete_bikes["bike_types"]["name"] ?? "-"
                : "-"
              : title === "Time"
              ? timeFormatter((session.total_time / 1000)?.toString())
              : title === "Elevation Gain"
              ? units === "metric"
                ? `${session.elevation_gain?.toFixed(1) ?? "-"} m`
                : `${
                    convert(session.elevation_gain as number)
                      .from("m")
                      .to("ft")
                      ?.toFixed(0) ?? "-"
                  } mi`
              : title === "Avg Speed"
              ? units === "metric"
                ? `${session.average_speed?.toFixed(1) ?? "-"} kph`
                : `${
                    (session.average_speed * 0.6213711922)?.toFixed(1) ?? "-"
                  } mph`
              : title === "Avg Power"
              ? (session.average_power?.toFixed(1) ?? "-") + " W"
              : (session.average_heart_rate?.toFixed(1) ?? "-") + " bpm"}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}

function CollapsibleData(props: CollapsibleDataProps) {
  const units = useUnits();
  return (
    <TableRow
      hover
      sx={{ background: props.isPrinting ? "#F7F3F0" : "#493e4a" }}
    >
      <TableCell
        style={{
          paddingLeft: "55px",
        }}
      >
        <Typography
          variant="h4"
          style={{ color: props.isPrinting ? "#000000" : "#eba16c" }}
        >
          {props.title}
        </Typography>
      </TableCell>
      {props.positionStatistics?.map((pos, i) => (
        <TableCell
          key={i}
          sx={{ color: props.isPrinting ? "#000000" : "#F7F3F0" }}
        >
          {props.title === "Avg Speed"
            ? pos.find((data) => data.positionType === props.position)?.[
                `${props.field}`
              ] || "-" !== "-"
              ? units === "metric"
                ? pos
                    .find((data) => data.positionType === props.position)
                    ?.[`${props.field}`]?.toFixed(1) + ` ${props.unit}`
                : (
                    pos.find((data) => data.positionType === props.position)?.[
                      `${props.field}`
                    ] * 0.6213711922
                  )?.toFixed(1) + ` ${props.unit}`
              : "-"
            : pos.find((data) => data.positionType === props.position)?.[
                `${props.field}`
              ] || "-" !== "-"
            ? pos
                .find((data) => data.positionType === props.position)
                ?.[`${props.field}`]?.toFixed(1) + ` ${props.unit}`
            : "-"}
        </TableCell>
      ))}
    </TableRow>
  );
}

export default function CompareModal(props: CompareModalProps) {
  const user = useUser();
  const supabase = useSupabase();
  const units = useUnits();
  const zones = useMemo(
    () => [
      "Zone 1",
      "Zone 2",
      "Zone 3",
      "Zone 4",
      "Zone 5",
      "Zone 6",
      "Zone 7",
    ],
    [],
  );

  // Sessions Related
  const sessionsPromise = useCallback(
    () =>
      supabase
        .from("session")
        .select("*,session_statistics(*),athlete_bikes(bike_types(name))")
        .in("id", props.ids as string[])
        .then((res) => res.data),
    [supabase],
  );

  const sessionsCall = useAsyncState<SupabaseCall<typeof sessionsPromise>>();

  useEffect(() => {
    if (isUnloaded(sessionsCall)) {
      sessionsCall.fire(async () => sessionsPromise());
    }
  }, [sessionsCall, sessionsPromise]);

  // Metrics Related
  const metricsPromise = useCallback(
    () =>
      supabase
        .from("metrics")
        .select("*")
        .in("session_id", props.ids as string[])
        .order("timestamp", { ascending: true })
        .then((res) =>
          res.data
            .filter((el) => el.heart_rate !== 0)
            .filter((_, i) => i % 5 === 0),
        ),
    [supabase],
  );

  const metricsCall = useAsyncState<SupabaseCall<typeof metricsPromise>>();

  useEffect(() => {
    if (isUnloaded(metricsCall)) {
      metricsCall.fire(async () => metricsPromise());
    }
  }, [metricsCall, metricsPromise]);

  // Zones Related
  const zonesPromise = useCallback(
    () =>
      supabase
        .from("athlete")
        .select("heart_rate_zones,power_zones,max_heart_rate,ftp")
        .eq("user_id", user.id)
        .then((res) => ({
          power: res.data?.[0]?.power_zones?.map((val) =>
            Math.round(val * res.data[0].ftp),
          ),
          heartRate: res.data?.[0]?.heart_rate_zones?.map((val) =>
            Math.round(val * res.data[0].max_heart_rate),
          ),
        })),
    [supabase],
  );

  const zonesCall = useAsyncState<SupabaseCall<typeof zonesPromise>>();

  useEffect(() => {
    if (isUnloaded(zonesCall)) {
      zonesCall.fire(async () => zonesPromise());
    }
  }, [zonesCall, zonesPromise]);

  // Heart Rate Zones
  const hrz_zone_with_stats: Array<ZoneWithStats> = useMemo(() => {
    if (
      isFulfilled(zonesCall) &&
      isFulfilled(sessionsCall) &&
      isFulfilled(metricsCall)
    ) {
      const hrz: Array<Zone> = zoneEdgesToZoneType(
        zonesCall.result.heartRate ?? [],
      );
      return sessionsCall.result.map((session) => {
        const hrz_count = hrz.map((_) => 0);
        const total_time = session.total_time;
        const newMetrics = metricsCall.result.filter(
          (metric) => metric.session_id === session.id,
        );

        for (const metric of newMetrics) {
          hrz_count[hrz.findIndex((z) => valueInZone(metric.heart_rate, z))]++;
        }
        const hrz_percentages = hrz_count.map((v) => v / newMetrics.length);
        const hrz_time = hrz_percentages.map((p) => total_time * p);
        return hrz.map((z, i) => ({
          ...z,
          index: i + 1,
          percentage: hrz_percentages[i],
          total_time: hrz_time[i],
        }));
      });
    }
  }, [zonesCall, metricsCall, sessionsCall]);

  // Power Zones
  const prz_zone_with_stats: Array<ZoneWithStats> = useMemo(() => {
    if (
      isFulfilled(zonesCall) &&
      isFulfilled(sessionsCall) &&
      isFulfilled(metricsCall)
    ) {
      const prz: Array<Zone> = zoneEdgesToZoneType(
        zonesCall.result.power ?? [],
      );
      return sessionsCall.result.map((session) => {
        const prz_count = prz.map((_) => 0);
        const total_time = session.total_time;
        const newMetrics = metricsCall.result.filter(
          (metric) => metric.session_id === session.id,
        );

        for (const metric of newMetrics) {
          prz_count[prz.findIndex((z) => valueInZone(metric.bike_power, z))]++;
        }
        const prz_percentages = prz_count.map((v) => v / newMetrics.length);
        const prz_time = prz_percentages.map((p) => total_time * p);
        return prz.map((z, i) => ({
          ...z,
          index: i + 1,
          percentage: prz_percentages[i],
          total_time: prz_time[i],
        }));
      });
    }
  }, [zonesCall, metricsCall, sessionsCall]);

  const positionStatistics: Array<
    Omit<PositionStatistics, "positionType"> &
      {
        positionType: string;
      }[]
  > = useMemo(() => {
    if (isFulfilled(sessionsCall)) {
      return sessionsCall.result?.map((session, index) => {
        return (session.session_statistics as Statistics)?.map((stat) => ({
          ...stat,
          positionType: ((position) =>
            position.primary_name +
            (position.secondary_name ? ` - ${position.secondary_name}` : ""))(
            stat.default_position_id !== null
              ? props.defaultPositions.find(
                  (pos) => pos.id === stat.default_position_id,
                )
              : props.customPositions
                  .filter((pos) => pos.bike_id === session.athlete_bike_id)
                  .find((pos) => pos.id === stat.custom_position_id),
          ),
        }));
      }) as any;
    }
    return [];
  }, [sessionsCall, props.defaultPositions]);

  const [expandedRow, setExpandedRow] = useState(null);

  const handleRowClick = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  // Print
  const componentRef = useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const customToPrint = useCallback((printWindow) => {
    const printContent =
      printWindow.contentDocument || printWindow.contentWindow?.document;
    const printedScrollContainer =
      printContent.querySelector("#TableContainer");

    printedScrollContainer.style.maxHeight = "none";
    printedScrollContainer.style.overflow = "visible !important";
    printedScrollContainer.style.height = "fit-content !important";

    printWindow.contentWindow.print();

    // print must return a Promise
    return Promise.resolve();
  }, []);

  const [isPrinting, setIsPrinting] = useState(false);
  const promiseResolveRef = useRef(null);
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  const handlePrintModal = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Compared Sessions",
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
    print: customToPrint,
    removeAfterPrint: true,
  });

  return (
    <>
      {isFulfilled(zonesCall) &&
        isFulfilled(sessionsCall) &&
        isFulfilled(metricsCall) && (
          <Modal
            open={props.showModal}
            onClose={() => props.handleCloseModal()}
          >
            <Paper
              sx={{ background: isPrinting ? "white" : "#3D333D" }}
              ref={componentRef}
              className="scroll-container"
            >
              <Box p={2}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!isPrinting ? (
                    <>
                      <Typography
                        variant="h2"
                        color={isPrinting ? "black" : "#F7F3F0"}
                        sx={{ flexGrow: 1, textAlign: "center", ml: "200px" }}
                      >
                        Compared Sessions
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePrintModal}
                        sx={{ margin: "10px" }}
                      >
                        Print
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={props.handleCloseModal}
                      >
                        Close
                      </Button>
                    </>
                  ) : (
                    <>
                      <img src={Minilogo} alt="Logo" />
                      <Typography
                        variant="h2"
                        color={"black"}
                        sx={{ textAlign: "center" }}
                      >
                        Compared Sessions Report
                      </Typography>
                      <Typography color={"black"} sx={{ textAlign: "center" }}>
                        {dayjs().format("DD/MM/YYYY")}
                      </Typography>
                    </>
                  )}
                </Box>

                <TableContainer
                  id="TableContainer"
                  sx={{ maxHeight: { xl: 900, md: 670 } }}
                >
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ paddingLeft: "55px" }}>
                          <Typography
                            variant="h3"
                            color={isPrinting ? "#F7F3F0" : "#BC6D29"}
                          >
                            Title
                          </Typography>
                        </TableCell>
                        {sessionsCall.result?.map((session, index) => (
                          <TableCell key={index} sx={{ paddingLeft: "25px" }}>
                            <Typography
                              variant="h3"
                              color={isPrinting ? "#F7F3F0" : "#BC6D29"}
                            >
                              {session.recording_name}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Normal Data */}
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Date"
                        isPrinting={isPrinting}
                      />
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Distance"
                        isPrinting={isPrinting}
                      />
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Type"
                        isPrinting={isPrinting}
                      />
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Time"
                        isPrinting={isPrinting}
                      />
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Elevation Gain"
                        isPrinting={isPrinting}
                      />
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Avg Speed"
                        isPrinting={isPrinting}
                      />
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Avg Power"
                        isPrinting={isPrinting}
                      />
                      <NormalData
                        sessionCall={sessionsCall}
                        title="Avg HR"
                        isPrinting={isPrinting}
                      />

                      {/* Power Zones */}
                      <TableRow>
                        <TableCell colSpan={sessionsCall.result.length + 1}>
                          <Typography
                            variant="h3"
                            align="center"
                            color={isPrinting ? "#000000" : "#BC6D29"}
                            sx={{ p: 2 }}
                          >
                            Power Zones
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {prz_zone_with_stats &&
                        zones?.map((zone, index) => (
                          <TableRow
                            key={index}
                            hover
                            sx={{
                              background: isPrinting ? "#F7F3F0" : "#342C35",
                            }}
                          >
                            <TableCell sx={{ paddingLeft: "55px" }}>
                              <Typography
                                variant="h4"
                                sx={{
                                  color: isPrinting ? "#000000" : "#E28E54",
                                  fontWeight: isPrinting ? "bold" : "",
                                }}
                              >
                                {zone} (
                                {prz_zone_with_stats[0] &&
                                !prz_zone_with_stats[0][index]?.max
                                  ? `> ${prz_zone_with_stats[0][index]?.min} W`
                                  : !prz_zone_with_stats[0][index]?.min
                                  ? `< ${prz_zone_with_stats[0][index]?.max} W`
                                  : `${prz_zone_with_stats[0][index]?.min} - ${prz_zone_with_stats[0][index]?.max} W`}
                                )
                              </Typography>
                            </TableCell>
                            {prz_zone_with_stats?.map((data, i) => (
                              <TableCell
                                key={i}
                                sx={{
                                  color: isPrinting ? "#000000" : "#F7F3F0",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "25%",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{ marginRight: "15px" }}
                                  >
                                    {isNaN(data[index]["total_time"] / 1000)
                                      ? ""
                                      : timeFormatter(
                                          (
                                            data[index]["total_time"] / 1000
                                          )?.toString(),
                                        )}
                                  </Typography>
                                  <Typography variant="body2">
                                    {isNaN(data[index]["percentage"])
                                      ? "-"
                                      : (
                                          data[index]["percentage"] * 100
                                        )?.toFixed(0) ?? "-"}
                                    %
                                  </Typography>
                                </Box>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}

                      {/* Heart Rate Zones */}
                      <TableRow>
                        <TableCell colSpan={sessionsCall.result.length + 1}>
                          <Typography
                            variant="h3"
                            align="center"
                            color={isPrinting ? "#000000" : "#BC6D29"}
                            sx={{ p: 2 }}
                          >
                            Heart Rate Zones
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {hrz_zone_with_stats &&
                        zones.slice(0, 5)?.map((zone, index) => (
                          <TableRow
                            key={index}
                            hover
                            sx={{
                              background: isPrinting ? "#F7F3F0" : "#2C262D",
                            }}
                          >
                            <TableCell sx={{ paddingLeft: "55px" }}>
                              <Typography
                                variant="h4"
                                sx={{
                                  color: isPrinting ? "#000000" : "#E28E54",
                                  fontWeight: isPrinting ? "bold" : "",
                                }}
                              >
                                {zone} (
                                {hrz_zone_with_stats[0] &&
                                !hrz_zone_with_stats[0][index]?.max
                                  ? `> ${hrz_zone_with_stats[0][index]?.min} bpm`
                                  : !hrz_zone_with_stats[0][index]?.min
                                  ? `< ${hrz_zone_with_stats[0][index]?.max} bpm`
                                  : `${hrz_zone_with_stats[0][index]?.min} - ${hrz_zone_with_stats[0][index]?.max} bpm`}
                                )
                              </Typography>
                            </TableCell>
                            {hrz_zone_with_stats?.map((data, i) => (
                              <TableCell
                                key={i}
                                sx={{
                                  color: isPrinting ? "#000000" : "#F7F3F0",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "25%",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{ marginRight: "15px" }}
                                  >
                                    {isNaN(data[index]["total_time"] / 1000)
                                      ? ""
                                      : timeFormatter(
                                          (
                                            data[index]["total_time"] / 1000
                                          )?.toString(),
                                        )}
                                  </Typography>
                                  <Typography variant="body2">
                                    {isNaN(data[index]["percentage"])
                                      ? "-"
                                      : (
                                          data[index]["percentage"] * 100
                                        )?.toFixed(0) ?? "-"}
                                    %
                                  </Typography>
                                </Box>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      <TableRow style={{ pageBreakBefore: "always" }} />
                      {/* Positions */}
                      <TableRow>
                        <TableCell colSpan={sessionsCall.result.length + 1}>
                          <Typography
                            variant="h3"
                            align="center"
                            color={isPrinting ? "#000000" : "#BC6D29"}
                            sx={{ p: 2 }}
                          >
                            Positions
                          </Typography>
                        </TableCell>
                      </TableRow>

                      {allBikePositions?.map((position, index) => (
                        <Fragment key={index}>
                          <TableRow
                            hover
                            onClick={() => handleRowClick(index)}
                            sx={{
                              background: isPrinting ? "#F7F3F0" : "#342C35",
                            }}
                          >
                            <TableCell>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {expandedRow === index ? (
                                  <KeyboardArrowUp sx={{ color: "#E28E54" }} />
                                ) : (
                                  <KeyboardArrowDown
                                    sx={{ color: "#E28E54" }}
                                  />
                                )}
                                <Typography
                                  variant="h4"
                                  style={{ paddingLeft: "5px" }}
                                  sx={{
                                    color: isPrinting ? "#000000" : "#E28E54",
                                    fontWeight: isPrinting ? "bold" : "",
                                  }}
                                >
                                  {position}
                                </Typography>
                              </Box>
                            </TableCell>
                            {positionStatistics?.map((pos, i) => (
                              <TableCell
                                key={i}
                                sx={{
                                  color: isPrinting ? "#000000" : "#F7F3F0",
                                }}
                              >
                                {pos.find(
                                  (data) => data.positionType === position,
                                )?.["position_percentage"] * 100 ?? "-"
                                  ? (
                                      pos.find(
                                        (data) =>
                                          data.positionType === position,
                                      )?.["position_percentage"] * 100
                                    )?.toFixed(1)
                                  : "-"}{" "}
                                %
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                padding: 0,
                              }}
                              colSpan={5}
                            >
                              <Collapse
                                in={expandedRow === index}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Table size="small">
                                  <TableBody>
                                    <CollapsibleData
                                      title="Avg Speed"
                                      positionStatistics={positionStatistics}
                                      position={position}
                                      field="speed_average"
                                      unit={units === "metric" ? "kph" : "mph"}
                                      isPrinting={isPrinting}
                                    />
                                    <CollapsibleData
                                      title="Avg Power"
                                      positionStatistics={positionStatistics}
                                      position={position}
                                      field="power_average"
                                      unit="W"
                                      isPrinting={isPrinting}
                                    />
                                    <CollapsibleData
                                      title="Avg Heart Rate"
                                      positionStatistics={positionStatistics}
                                      position={position}
                                      field="heart_rate_average"
                                      unit="bpm"
                                      isPrinting={isPrinting}
                                    />
                                    <CollapsibleData
                                      title="Avg Cadence"
                                      positionStatistics={positionStatistics}
                                      position={position}
                                      field="cadence_average"
                                      unit="rpm"
                                      isPrinting={isPrinting}
                                    />
                                    <CollapsibleData
                                      title="Avg Slope"
                                      positionStatistics={positionStatistics}
                                      position={position}
                                      field="slope_average"
                                      unit="%"
                                      isPrinting={isPrinting}
                                    />
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Modal>
        )}
    </>
  );
}
