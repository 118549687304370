import {
Box,
IconButton,
styled,
Typography,
IconButtonProps,
Accordion,
AccordionSummary,
AccordionDetails,
Divider,
} from "@mui/material";
import { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import arrowBack from "../../assets/img/layout/coach/MyAthletes/arrowBack.svg";
import { toHHMMSS } from "src/utils/common";
import { useUser } from "src/components/Authenticated";
import PreloadComponent from "src/utils/PreloadComponent";
import { Tables } from "src/utils/DatabaseDefinitions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignedDesigner from "../customViews/LibraryCyclist/DnDCyclist/AssignedDesigner";
import DesignerContextProvider from "../customViews/LibraryCyclist/DnDCyclist/DesignerContext";
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import DragOverlayWrapper from "../customViews/LibraryCyclist/DnDCyclist/DragOverlayWrapper";
import convert from "convert-units";
import { useUnits } from "src/components/Authenticated/CyclistAuthenticated";

export default function AssignedWorkoutView() {
    const params = useParams()
    const user = useUser();
    const mouseSensor = useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10, // Enable sort function when dragging 10px   💡 here!!!
      },
    });
    const touchSensor = useSensor(TouchSensor,{
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    });    const sensors = useSensors(mouseSensor, touchSensor);
    return (
        <>
            <PreloadComponent<{
                assignedWorkout: Tables<'assigned_workout'> & { assigned_workout_step: Tables<'assigned_workout_step'>[] };
            }>
                promises={{
                assignedWorkout: async (supabase) => 
                    supabase
                    .from("assigned_workout")
                    .select("*,assigned_workout_step(*)")
                    .eq("id", Number(params.assignedWorkoutId))
                    .eq("user_id", user.id)
                    .single()
                    .then((res) => res.data),
                }}
                component={(props) => (
                  <>
                    {
                      props.assignedWorkout.assigned_training_plan_id ? (
                        <AssignedCoachWorkoutView assignedWorkout={props.assignedWorkout}/>
                      ) : (
                        <DesignerContextProvider>
                          <DndContext sensors={sensors}>
                            <AssignedDesigner assignedWorkout={props.assignedWorkout}/>
                            <DragOverlayWrapper />
                          </DndContext>
                        </DesignerContextProvider>
                      )
                    }
                  </>
                )}
            />
        </>
    );
}

function AssignedCoachWorkoutView({assignedWorkout}: {assignedWorkout: Tables<'assigned_workout'> & { assigned_workout_step: Tables<'assigned_workout_step'>[] }}) {
    const navigate = useNavigate();
    const units = useUnits();
    return (
      <>
          <Box
            sx={{
              width: "96%",
              padding: "1em",
              background: "#201B20",
              borderRadius: ".625rem",
              marginTop: ".625rem",
              marginLeft: "1.5625rem",
              height: { xl: "1000px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              <IconButton
                sx={{ padding: ".125rem" }}
                onClick={() => navigate(-1)}
              >
                <img src={arrowBack} />
              </IconButton>
              <Typography>Back to Calendar</Typography>
            </Box>
            <Box height={30}/>
            <Box
              sx={{
                display: "flex",
                paddingX: "1.875rem",
                marginBottom: ".625rem",
              }}
            >
                <Typography variant="h2">{assignedWorkout.name}</Typography>
            </Box>
  
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "65%",
                  gap: 2,
                  marginTop: ".3125rem",
                  height: { xl: "820px", md: "520px" },
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    border: "1px solid black",
                    marginX: "30px",
                    borderRadius: "5px",
                    display: "flex",
                    flexdirecation: "column",
                    maxHeight: { xl: "850px", md: "520px" },
                    overflowY: "auto",
                    marginTop: ".3125rem",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    {/* When i have 0 elements */}
                    {assignedWorkout.assigned_workout_step.length === 0 && (
                      <Box
                        sx={{
                          border: ".0625rem dashed #64605D",
                          background: "#2C262D",
                          borderRadius: ".375rem",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography>Drag and drop step here</Typography>
                      </Box>
                    )}
                    {/* While adding the first one */}
                    {assignedWorkout.assigned_workout_step.length === 0 && (
                      <Box
                        sx={{
                          background: "white",
                          width: "100%",
                          opacity: "0.25",
                          height: "100px",
                        }}
                      />
                    )}
                    {/* My elements */}
                    {assignedWorkout.assigned_workout_step.length > 0 && (
                      <Box>
                        {assignedWorkout.assigned_workout_step.map((element) => {
                            return <Box
                                key={element.id}
                                sx={{
                                display: "flex",
                                flexDirection: "column",
                                position: "relative",
                                height: "100%",
                                margin: "10px",
                                }}
                            >
                                <Box sx={{ flex: 1, width: "100%" }}>
                                <DesignerComponent
                                    elementInstance={element}
                                />
                                </Box>
                            </Box>
                        })}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
  
              {/* RIGHT BOX */}
              <Box width={"35%"}>
                <Typography
                  variant="h2"
                  sx={{
                    marginBottom: { xl: "3rem", md: "12px" },
                    marginTop: "1rem",
                  }}
                >
                  Description Workout
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: 10,
                    marginBottom: { xl: "2.5rem", md: ".3rem" },
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h4">Duration</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        color: "#DD4F4A",
                      }}
                    >
                      {toHHMMSS(assignedWorkout.duration) ??
                        "--"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h4">TSS</Typography>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        color: "#DD4F4A",
                      }}
                    >
                      {assignedWorkout.tss ?? "--"}
                    </Typography>
                  </Box>
  
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="h4">Distance</Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: 700,
                        color: "#DD4F4A",
                        marginBottom: "0.5rem",
                      }}
                    >
                      { 
                        units === "metric"
                        ? `${assignedWorkout.distance.toFixed(2) ?? "0"} km`
                        : `${
                            assignedWorkout.distance
                              ? convert(assignedWorkout.distance)
                                  .from("km")
                                  .to("mi")
                                  .toFixed(2)
                              : "0"
                          } mi`
                      }
                    </Typography>
                  </Box>
                </Box>
  
                <Box sx={{ marginBottom: { xl: "2rem", md: "0.5px" } }}>
                  <Typography variant="h4" sx={{ marginBottom: { xl: "1rem" } }}>
                    Bike Type
                  </Typography>
                  <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        color: "#DD4F4A",
                      }}
                    >
                      {assignedWorkout.bike_type}
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: { xl: "2rem", md: "0.5px" } }}>
                    <Typography variant="h4" sx={{ marginBottom: { xl: "1rem" } }}>
                        Position Goal
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        color: "#DD4F4A",
                      }}
                    >
                      {assignedWorkout.position_goal}
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: { xl: "5rem", md: "0.5rem" } }}>
                  <Typography variant="h4" sx={{ marginBottom: { xl: "1rem" } }}>
                    Workout Type
                  </Typography>
                  <Typography
                      sx={{
                        fontSize: "1.125rem",
                        fontWeight: 700,
                        color: "#DD4F4A",
                      }}
                    >
                      {assignedWorkout.workout_type}
                    </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
      </>
    );
  }
  
interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));
  
  function DesignerComponent({
    elementInstance,
  }: {
    elementInstance: Tables<'assigned_workout_step'>;
  }) {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = useCallback(() => {
      setExpanded((prev) => !prev);
    }, []);
    const units = useUnits();
    return (
      <>
        <Box
          sx={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}
        >
          <Accordion expanded={expanded}>
            <AccordionSummary
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                paddingLeft: "0.5%",
                paddingRight: "0px",
                margin: expanded ? "0px" : "0px",
                background: expanded
                  ? `linear-gradient(90deg, ${
                      elementInstance.type === "Warmup"
                        ? "#E28E54"
                        : elementInstance.type === "Recovery"
                        ? "#00B8D9"
                        : elementInstance.type === "Active"
                        ? "#ffcf40"
                        : elementInstance.type === "Cooldown"
                        ? "#EFC09F"
                        : "#726174"
                    } 0.5%, #3D343E 0.5%)`
                  : `linear-gradient(90deg, ${
                    elementInstance.type === "Warmup"
                        ? "#E28E54"
                        : elementInstance.type === "Recovery"
                        ? "#00B8D9"
                        : elementInstance.type === "Active"
                        ? "#ffcf40"
                        : elementInstance.type === "Cooldown"
                        ? "#EFC09F"
                        : "#726174"
                    } 0.5%, #2C262D 0.5%)`,
                borderRadius: "6px",
                borderBottomLeftRadius: expanded ? "0px" : "6px",
                "& .MuiAccordionSummary-content": {
                  margin: 0,
                  cursor: "default",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    justifyContent: "space-between",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {/* Type */}
                  <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "1rem" }}>
                    <Typography variant="h4">
                      {elementInstance.type === "Cooldown"
                        ? "Cool Down"
                        : elementInstance.type[0].toUpperCase() +
                        elementInstance.type.slice(1)}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <ExpandMore
                      expand={expanded}
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    paddingX: "1rem",
                    paddingTop: "1rem",
                    paddingBottom: "0.5rem",
                    background: "#2C262D",
                    borderEndStartRadius: expanded ? "0px" : "6px",
                    borderEndEndRadius: expanded ? "0px" : "6px",
                  }}
                >
                  <Box sx={{ width: { md: "80px", xl: "100px" } }}>
                    <Typography variant="h5" color={"#DD4F4A"}>
                      {elementInstance.duration_type === "Time"
                        ? isNaN(elementInstance.duration)
                          ? "00:00:00"
                          : toHHMMSS(elementInstance.duration)
                        : elementInstance.duration_type === "Distance"
                        ? units === 'metric' 
                        ? elementInstance.duration + " km" 
                        : convert(elementInstance.duration).from("km").to("mi").toFixed(2) + ' mi'
                        : "LAP"}
                    </Typography>
                    <Typography variant="subtitle2">
                      {elementInstance.duration_type === "Time"
                        ? "Total Time"
                        : elementInstance.duration_type === "Distance"
                        ? "Total Distance"
                        : ""}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5" color={"#DD4F4A"}>
                      {elementInstance.primary_target_type.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle2">Primary Target</Typography>
                  </Box>
                  <Box sx={{ width: "50px" }}>
                    <Typography variant="h5" color={"#DD4F4A"}>
                      {elementInstance.position.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle2">Position</Typography>
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: "0px",
                background: `linear-gradient(90deg, ${
                    elementInstance.type === "Warmup"
                    ? "#E28E54"
                    : elementInstance.type === "Recovery"
                    ? "#00B8D9"
                    : elementInstance.type === "Active"
                    ? "#ffcf40"
                    : elementInstance.type === "Cooldown"
                    ? "#EFC09F"
                    : "#726174"
                } 0.5%, #2C262D 0.5%)`,
                borderEndStartRadius: "6px",
                borderEndEndRadius: "6px",
              }}
            >
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  padding: "1.5rem",
                }}
              >
                {/* Type */}
                <Box>
                    <Typography variant="h4">Type</Typography>
                    <Typography variant="h4">
                        {elementInstance.type.toUpperCase()}
                    </Typography>
                </Box>
                {/* Duration  */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4">Duration</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "space-between", xl: "flex-start" },
                      gap: { md: 2, xl: 4 },
                    }}
                  >
                    <Typography variant="h4">
                        {elementInstance.duration_type.toUpperCase()}
                    </Typography>
  
                    {/* If Time & Distance , if lap none */}
                    {
                      elementInstance.duration_type === "Time" 
                      ? 
                          <Box sx={{ display: "flex" }}>
                          <Typography variant="h4">
                              {toHHMMSS(elementInstance.duration)}
                          </Typography>
                        </Box> 
                      : elementInstance.duration_type === "Distance" ? 
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="h4">
                            { 
                              units === "metric"
                              ? `${elementInstance.duration.toFixed(2) ?? "0"} km`
                              : `${
                                  elementInstance.duration
                                    ? convert(elementInstance.duration)
                                        .from("km")
                                        .to("mi")
                                        .toFixed(2)
                                    : "0"
                                } mi`
                            }
                          </Typography>
                        </Box>
                      : null
                    }
                  </Box>
                </Box>
                {/* Primary Target */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "space-between", xl: "flex-start" },
                      gap: { xl: 29 },
                    }}
                  >
                    <Typography variant="h4" sx={{ marginBottom: "0.5rem" }}>
                      Primary Target
                    </Typography>
                    {elementInstance.primary_target_type === "Heart Rate" && (
                      <Typography
                        variant="h4"
                        sx={{
                          marginBottom: "0.5rem",
                          display: "flex",
                          width: 260,
                        }}
                      >
                        Target
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "space-between", xl: "flex-start" },
                      gap: { md: 2, xl: 4 },
                    }}
                  >
                    <Typography variant="h4">
                        {elementInstance.primary_target_type.toUpperCase()}
                    </Typography>
                    {(elementInstance.primary_target_type === "Speed" ||
                      elementInstance.primary_target_type === "Cadence") && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h4">
                            {elementInstance.primary_target[0]}
                        </Typography>
                        <Typography
                          sx={{
                            opacity: "0.5",
                            width: 60,
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          to
                        </Typography>
                        <Typography variant="h4">
                            {elementInstance.primary_target[1]}
                        </Typography>
                        <Typography sx={{ paddingLeft: "10px", opacity: "0.5" }}>
                        {elementInstance.primary_target_type === "Speed"
                            ? "kph "
                            : "rpm"}
                        </Typography>
                      </Box>
                    )}
                    {elementInstance.primary_target_type === "Power Zones" && (
                        <Typography variant="h4">
                            POWER ZONE {elementInstance.primary_target[0] + 1}
                        </Typography>
                    )}
                    {elementInstance.primary_target_type === "Power" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography variant="h4">Power Target</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h4">
                            {elementInstance.primary_target[0]}
                        </Typography>
                          <Typography
                            sx={{
                              opacity: "0.5",
                              width: 60,
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            to
                          </Typography>
                            <Typography variant="h4">
                                {elementInstance.primary_target[1]}
                            </Typography>
                            <Typography sx={{ opacity: "0.5", paddingLeft: "10px" }}>
                                {elementInstance.primary_target_unit === "watts" ? "W " : "%FTP"}
                            </Typography>
                        </Box>
                      </Box>
                    )}
                    {elementInstance.primary_target_type === "Heart Rate Zones" && (
                        <Typography variant="h4">
                            HEART RATE {elementInstance.primary_target[0] + 1}
                        </Typography>
                    )}
                    {elementInstance.primary_target_type === "Heart Rate" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h4">
                            {elementInstance.primary_target[0]}
                        </Typography>
                          <Typography
                            sx={{
                              opacity: "0.5",
                              width: 60,
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            to
                          </Typography>
                          <Typography variant="h4">
                                {elementInstance.primary_target[1]}
                            </Typography>
                            <Typography sx={{ paddingLeft: "10px", opacity: "0.5" }}>
                              bpm
                            </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                {/* Secondary Target */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "space-between", xl: "flex-start" },
                      gap: { xl: 26.5 },
                    }}
                  >
                    <Typography variant="h4" sx={{ marginBottom: "0.5rem" }}>
                      Secondary Target
                    </Typography>
                    {elementInstance.secondary_target_type === "Heart Rate" && (
                      <Typography
                        variant="h4"
                        sx={{
                          marginBottom: "0.5rem",
                          display: "flex",
                          width: 260,
                        }}
                      >
                        Target
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: { md: "space-between", xl: "flex-start" },
                      gap: { md: 2, xl: 4 },
                    }}
                  >
                    <Typography variant="h4">
                        {elementInstance.secondary_target_type.toUpperCase()}
                    </Typography>
                    {(elementInstance.secondary_target_type === "Speed" ||
                      elementInstance.secondary_target_type === "Cadence") && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h4">
                            {elementInstance.secondary_target[0]}
                        </Typography>
                        <Typography
                          sx={{
                            opacity: "0.5",
                            width: 60,
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          to
                        </Typography>
                        <Typography variant="h4">
                            {elementInstance.secondary_target[1]}
                        </Typography>
                        <Typography sx={{ paddingLeft: "10px", opacity: "0.5" }}>
                        {elementInstance.secondary_target_type === "Speed"
                            ? "kph "
                            : "rpm"}
                        </Typography>
                      </Box>
                    )}
                    {elementInstance.secondary_target_type === "Power Zones" && (
                        <Typography variant="h4">
                            POWER ZONE {elementInstance.secondary_target[0] + 1}
                        </Typography>
                    )}
                    {elementInstance.secondary_target_type === "Power" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography variant="h4">Power Target</Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="h4">
                                {elementInstance.secondary_target[0]}
                            </Typography>
                          <Typography
                            sx={{
                              opacity: "0.5",
                              width: 60,
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            to
                          </Typography>
                          <Typography variant="h4">
                            {elementInstance.secondary_target[1]}
                            </Typography>
                            <Typography sx={{ paddingLeft: "10px", opacity: "0.5" }}>
                              {elementInstance.secondary_target_unit === "watts" ? "W " : "%FTP"}
                            </Typography>
                        </Box>
                      </Box>
                    )}
                    {elementInstance.secondary_target_type === "Heart Rate Zones" && (
                        <Typography variant="h4">
                            HEART RATE {elementInstance.secondary_target[0] + 1}
                        </Typography>
                    )}
                    {elementInstance.secondary_target_type === "Heart Rate" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h4">
                            {elementInstance.secondary_target[0]}
                        </Typography>
                          <Typography
                            sx={{
                              opacity: "0.5",
                              width: 60,
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            to
                          </Typography>
                          <Typography variant="h4">
                                {elementInstance.secondary_target[1]}
                            </Typography>
                            <Typography sx={{ paddingLeft: "10px", opacity: "0.5" }}>
                              bpm
                            </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                {/* Position */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h4">Position</Typography>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography variant="h4">
                        {elementInstance.position.toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
                <Box
                    sx={{
                    border: "1px solid #64605D",
                    height: "120px",
                    width: "100%",
                    borderRadius: "4px",
                    padding: "1em",
                    }}
                >
                    <Box>
                    <Typography fontSize={"14px"} color={"#BDBCBE"} marginBottom={1}>
                        Notes
                    </Typography>
                    <Typography color={"#BDBCBE"}>
                        {elementInstance.notes}
                    </Typography>
                    </Box>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </>
    );
  }