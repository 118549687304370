import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import Designer from "./DnDCyclist/Designer";
import DesignerContextProvider from "./DnDCyclist/DesignerContext";
import DragOverlayWrapper from "./DnDCyclist/DragOverlayWrapper";
import { useCallback, useEffect } from "react";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import { useParams } from "react-router-dom";
import { useSupabase } from "src/contexts/SupabaseContext";
import SuspenseLoader from "src/components/SuspenseLoader";
  
export default function WorkoutBuilderCyclist() {
  const { workoutId } = useParams();
  const supabase = useSupabase();
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10, // Enable sort function when dragging 10px   💡 here!!!
    },
  });
  const touchSensor = useSensor(TouchSensor,{
    activationConstraint: {
      delay: 150,
      tolerance: 5,
    },
  });  //const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

    
  const workoutsPromise = useCallback(
    () =>
      workoutId && supabase
        .from("workout")
        .select("*,workout_step(*)")
        .eq("id", Number(workoutId))
        .single()
    ,[supabase],
  );

  const workoutsCall =
  useAsyncState<SupabaseCall<typeof workoutsPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(workoutsCall)) {
      workoutsCall.fire(async () => workoutsPromise());
    }
  }, [supabase, workoutsCall]);
  
  return (
    <div>
      <DesignerContextProvider>
        <DndContext sensors={sensors}>
          {
            isFulfilled(workoutsCall) 
            ? <Designer workout={workoutsCall.result?.data}/>
            : <SuspenseLoader/>
          }
          <DragOverlayWrapper />
        </DndContext>
      </DesignerContextProvider>
    </div>
  );
}