import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import calendarCyclist from "../../../../assets/img/layout/coach/Library/Plans/calendarcyclist.svg";
import DeleteOutlined from "../../../../assets/img/views/dashboard-sessions/DeleteOutlined.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import "../../../../assets/scss/DatePicker.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import enGB from "date-fns/locale/en-GB";
import dayjs from "dayjs";
import { useSupabase } from "src/contexts/SupabaseContext";
import { useUser } from "src/components/Authenticated";
import useAsyncState, { isFulfilled, isUnloaded } from "src/utils/Async";
import { SupabaseCall } from "src/utils/common";
import SuspenseLoader from "src/components/SuspenseLoader";
import { useNavigate } from "react-router-dom";

const dateDisplayStyle = `
.rdrCalendarWrapper .rdrDateDisplayWrapper {
  display: none;
}
.rdrCalendarWrapper .rdrStartEdge {
  color: #DD4F4A !important;
}
.rdrCalendarWrapper .rdrEndEdge {
  color: #DD4F4A !important;
}
.rdrCalendarWrapper .rdrInRange {
  color: #DD4F4A !important;
}

.rdrCalendarWrapper .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color : #DD4F4A !important;
}
`;

export type CoachPlanComponentProps = {
  athleteId: string;
  tab: string;
};

export default function CoachPlanComponent(props: CoachPlanComponentProps) {
  const navigate = useNavigate();
  const supabase = useSupabase();
  const user = useUser();
  const isMobileScreen = useMediaQuery("(max-width:920px)");
  const [selectedTrainingPlan, setSelectedTrainingPlan] = useState<number>();
  const [openDialog, setOpenDialog] = useState(false);

  const assignedTrainingPlanPromise = useCallback(() => {
    const query = supabase
      .from("assigned_training_plan")
      .select("*, assigned_workout!inner(*)")
      .eq("assigned_workout.user_id", props.athleteId)
      .eq("assigned_by", user.id);

    if (props.tab === "active") {
      query
        .lte("start_at", new Date().toISOString())
        .gte("end_at", new Date().toISOString());
    }

    if (props.tab === "scheduled") {
      query.gte("start_at", new Date().toISOString());
    }

    if (props.tab === "completed") {
      query.lte("end_at", new Date().toISOString());
    }

    return query.throwOnError();
  }, [supabase]);

  // @ts-ignore
  const assignedTrainingPlanCall = useAsyncState<SupabaseCall<typeof assignedTrainingPlanPromise>>(); // prettier-ignore

  useEffect(() => {
    if (isUnloaded(assignedTrainingPlanCall)) {
      assignedTrainingPlanCall.fire(async () => assignedTrainingPlanPromise());
    }
  }, [supabase, assignedTrainingPlanCall]);

  const deleteAssignedTrainingPlanPromise = useCallback(
    (assigned_training_plan_id: number) =>
      supabase
        .from("assigned_training_plan")
        .delete()
        .eq("id", assigned_training_plan_id)
        .throwOnError()
        .then((_) => assigned_training_plan_id),
    [supabase],
  );

  const deleteAssignedTrainingPlanCall = useAsyncState<number>();

  useEffect(() => {
    if (isFulfilled(deleteAssignedTrainingPlanCall)) {
      deleteAssignedTrainingPlanCall.unload();
      if (isFulfilled(assignedTrainingPlanCall)) {
        assignedTrainingPlanCall.unload();
      }
    }
  }, [deleteAssignedTrainingPlanCall]);

  return (
    <>
      {isFulfilled(assignedTrainingPlanCall) ? (
        assignedTrainingPlanCall.result.data.map((trainingPlan, index) => {
          const startDate = dayjs(trainingPlan.start_at);
          const endDate = dayjs(trainingPlan.end_at);
          const numberOfWeeks = Math.ceil(endDate.diff(startDate, "day") / 7);
          return (
            <Box
              key={trainingPlan.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
                ...(assignedTrainingPlanCall.result.data.length > 1 && {
                  borderBottom: "2px solid #DD4F4A",
                }),
              }}
            >
              <Stack direction={"row"} spacing={2}>
                <img
                  src={calendarCyclist}
                  width={isMobileScreen ? 35 : 50}
                  alt="calendar"
                />
                <Box>
                  <Typography variant="h2" marginBottom={2}>
                    {trainingPlan.name}
                  </Typography>
                  <Stack direction="row" spacing={1} marginBottom={2}>
                    <Chip
                      label={trainingPlan.difficulty}
                      size="small"
                      sx={{
                        backgroundColor: "#F89F5B",
                      }}
                    />
                  </Stack>
                  <Stack direction="row" spacing={2} marginBottom={2}>
                    <Typography>{numberOfWeeks} Weeks</Typography>
                    <Typography>
                      {(
                        trainingPlan.assigned_workout.length / numberOfWeeks
                      ).toFixed(1)}{" "}
                      Workouts/Week
                    </Typography>
                    <Typography>{trainingPlan.ride_type}</Typography>
                  </Stack>
                </Box>
              </Stack>

              <Stack alignItems={"flex-end"}>
                <Stack
                  direction="row"
                  spacing={2}
                  marginY={1}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography>
                    {startDate.format("DD MMM")} -{" "}
                    {endDate.format("DD MMM YYYY")}
                  </Typography>
                  {/* <IconButton onClick={handleClick} sx={{ padding: 0 }}>
                <CalendarMonthIcon sx={{ color: "#DD4F4A" }} fontSize="small" />
              </IconButton>
              {isOpen && (
                <Box
                  sx={{
                    borderRadius: "20px",
                    border: "3px solid",
                    borderColor: "#DD4F4A",
                    background: "#2A252B",
                    padding: "1.5em",
                    position: "absolute",
                    top: "60%",
                    left: "60%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <style>{dateDisplayStyle}</style>
                  <Box
                    sx={{
                      display: "flex",
                      alignSelf: "center",
                    }}
                  >
                    <Calendar
                      //   onChange={(date) => handleDate(date)}
                      date={startDate.toDate()}
                      editableDateInputs
                      locale={enGB}
                      preview={{
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        color: "#DD4F4A",
                      }}
                      color="#DD4F4A"
                    />
                  </Box>
                  <Typography
                    variant="h4"
                    marginY={"15px"}
                    textAlign={"center"}
                  >
                    Dates Selected : {dayjs(startDate).format("DD/MM/YYYY")} -{" "}
                    {dayjs(endDate).format("DD/MM/YYYY")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="coachVariant"
                      fullWidth
                      onClick={handleClick}
                    >
                      Apply
                    </Button>
                    <IconButton
                      disableRipple
                      size="small"
                      onClick={handleClick}
                      sx={{
                        padding: 1,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
              )} */}
                </Stack>
                <Link
                  onClick={() =>
                    navigate(`assigned-training-plan/${trainingPlan.id}`)
                  }
                  underline="none"
                  sx={{ color: "#E57B77", cursor: "pointer" }}
                >
                  <Typography textAlign={"end"}>View Details</Typography>
                </Link>
                <IconButton
                  onClick={() => {
                    setOpenDialog(true);
                    setSelectedTrainingPlan(trainingPlan.id);
                  }}
                >
                  <img src={DeleteOutlined} alt="delete-icon" width={"15px"} />
                </IconButton>
              </Stack>
            </Box>
          );
        })
      ) : (
        <SuspenseLoader color="#DD4F4A" />
      )}
      <Modal open={openDialog} onClose={setOpenDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2A252B",
            border: "3px solid #DD4F4A",
            borderRadius: "6px",
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="coach700" component="h2" mt={4}>
            Remove Assigned Training Plan
          </Typography>
          <Typography component="h2" mt={4}>
            Are you sure you want to remove this assigned training plan?
          </Typography>
          <Box sx={{ mt: 6, display: "flex", gap: 2 }}>
            <Button
              onClick={() => setOpenDialog(false)}
              variant="coachVariant2"
              size="small"
              sx={{ width: "155px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={(_) => {
                if (isUnloaded(deleteAssignedTrainingPlanCall)) {
                  deleteAssignedTrainingPlanCall.fire(async () =>
                    deleteAssignedTrainingPlanPromise(selectedTrainingPlan),
                  );
                  setOpenDialog(false);
                }
              }}
              variant="coachVariant"
              size="small"
              sx={{ width: "155px" }}
              color="error"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
